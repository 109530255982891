import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthService } from '../../../services/auth.service';
//import { ProviderService } from '../../../services/provider.service';
import { Admin, UserInfo } from '../../../models/all-interfaces';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
//import { TransactionService } from '../../../services/transaction.service';
import { ToastrService } from 'ngx-toastr';
//import { CurrencyConvertService } from '../../../services/currencyConvert.service';
import {environment} from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { SideMenuService } from 'src/services/side-menu.service';
import { WebsocketAbstractClass } from 'src/socket/websocketAbstractClass';

declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component2.html', 
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();
  pageInfo

  public user: Admin;
  public lang: string;
  public config: PerfectScrollbarConfigInterface = {};
  public userInfo: UserInfo;
 // providerDoc: Provider[];
//  withdraws: WithdrawRequest[];
  envUrl: string = environment.apiUrl;
  withdraws: any;
  providerDoc: any;
  

  constructor(
    private authService: AuthService,
    private ws:WebsocketAbstractClass,
    //private providerService: ProviderService,
    //private transactionService: TransactionService, 
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private router: Router,
    private titleService: Title,
    
   // private currencyConverter: CurrencyConvertService
  ) {
    this.lang = authService.getLang(); 
   // this.getProviderAboutDocument();
   // this.fetchTransaction();

   this.router.events
      .filter(event => event instanceof NavigationEnd)
      .map(() => this.activatedRoute)
      .map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
      .filter(route => route.outlet === 'primary')
      .mergeMap(route => route.data)
      .subscribe(event => {
        console.log(event['title']);
        this.translate.get(event['title']).subscribe(value => {
          this.titleService.setTitle(value);
        });
        this.pageInfo = event;
       // console.info(event)
      });
      ws.subscribe()
  }
 
 
  ngOnInit() {
    // this.sidebarnavItems = ROLE_SUPER_ADMIN_MENU.filter(sidebarnavItem => sidebarnavItem);
   console.info("userld",window);
   console.info("userld",(window)["__NSIA_USER__"] as any); 
    if ((<any>window)?.__NSIA_USER__ !== undefined) {
      this.authService.getWindowUser().subscribe(user => this.userInfo = user);
      //console.info("userd",this.userInfo);
     // this.sidebarnavItems = this[this.user.roles[0].toLowerCase() + '_menu'].filter(sidebarnavItem => sidebarnavItem);
    } else {
      console.info("ok")
      this.userInfo = JSON.parse( localStorage.getItem("userDetails")) as UserInfo;
      this.authService.updateWindowUser(this.userInfo); 
    //  console.info("user",this.user);
      /*this.authService.getApiUserInfo().subscribe((value) => {
      
        //console.info("leuser",this.user)
        this.user = value;
        this.authService.updateWindowUser(this.user);  
       
        //console.info("leuser",this.user)
       // this.sidebarnavItems = this[value.user.roles[0].toLowerCase() + '_menu'].filter(sidebarnavItem => sidebarnavItem);
      });*/
    }
  }
  changeLang(data:any) {
    
  }
  ngAfterViewInit() {
    console.info("after",this.user)
   }

   

ngViewInit() {
  console.info("afters",this.user)
}

logout() {
  this.authService.logout().subscribe(value => {
    if (value) {
      localStorage.removeItem('userDetails');
      this.ws.disconnect()
      this.ws.unsubscribe()
      return this.router.navigateByUrl('authentication/login');
    }
  });
}
  

  

  showProfil(provider) {
    const navigationExtras: NavigationExtras = {
      queryParams: { data: provider, admin: false, provider: true },
    };
    setTimeout(() => {
      return this.router.navigateByUrl('/pages/profile', navigationExtras);
    }, 0);
  }

  

}

import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from '../environments/environment';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Collaborateur, CollaborateurPoste, CollaborateurSuccursale, Entreprise, Equipe, Poste, Produit, ProduitCategorie, Prospect, Reseau, Succursale, UserInfo, Ville } from "src/models/all-interfaces";

@Injectable()
export class StaffService {
  private baseUrl: string = environment.staffCompaniesApi;

  private userSource: BehaviorSubject<any>;
  constructor(private http: HttpClient, private translate: TranslateService, private router: Router) {

  }

  /*addSuccursale(): Observable<Succursale[]> {
      return this.http.get<any>(this.baseUrl + '/query/succursales', { observe: 'response' as 'body', responseType: 'json' }).pipe(
          catchError(error => {
              console.error(error);
              return of(null);
          }),
          map((response: any) => {
              return response.body;
          })
      )
  }*/
  listSuccursales(): Observable<Succursale[]> {
    return this.http.get<any>(this.baseUrl + '/query/succursales', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }

  listPostes(): Observable<Poste[]> {
    return this.http.get<any>(this.baseUrl + '/query/postes', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }

  getPosteById(id: String): Observable<Poste> {
    return this.http.get<any>(this.baseUrl + '/query/postes/' + id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }

  deletePoste(id: any, roomId: any) {
    return this.http.delete(this.baseUrl + '/command/postes/' + id + '/', { observe: 'response' as 'body', responseType: 'text' });
  }

  deleteCollaborateur(id: any, roomId: any) {
    return this.http.delete(this.baseUrl + '/command/collaborateurs/' + id + '/', { observe: 'response' as 'body', responseType: 'text' });
  }


  updatePoste(data: any, roomId: String): Observable<any> {
    return this.http.put(this.baseUrl + '/command/postes/', data, { observe: 'response' as 'body', responseType: 'text' })
  }

  getCollaborateurById(id: String): Observable<Collaborateur> {
    return this.http.get<any>(this.baseUrl + '/query/collaborateurs/' + id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }

  getCollaborateurInfoByUserId(id: String): Observable<UserInfo> {
    return this.http.get<any>(this.baseUrl + '/query/collaborateurs/getCollaborateurInfosByUser/' + id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }
  getCollaborateursPostes(): Observable<CollaborateurPoste[]> {
    return this.http.get<any>(this.baseUrl + '/query/collaborateurPostes', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }

  getCollaborateursSuccursales(): Observable<CollaborateurSuccursale[]> {
    return this.http.get<any>(this.baseUrl + '/query/collaborateurSuccursales', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }

  addCollaborateur(data: any, roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/collaborateurs/', data, { observe: 'response' as 'body', responseType: 'text' })
  }
  addCollaborateurPoste(data: any, roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/collaborateurPostes/', data, { observe: 'response' as 'body', responseType: 'text' });
  }

  addCollaborateurSuccursale(data: any, roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/collaborateurSuccursales/', data, { observe: 'response' as 'body', responseType: 'text' });
  }

  updateCollaborateurSuccursale(data: any, roomId: String): Observable<any> {
    return this.http.put(this.baseUrl + '/command/collaborateurSuccursales/', data, { observe: 'response' as 'body', responseType: 'text' });
  }
  updateCollaborateurPoste(data: any, roomId: String): Observable<any> {
    return this.http.put(this.baseUrl + '/command/collaborateurPostes/', data, { observe: 'response' as 'body', responseType: 'text' });
  }
  uploadCollaborateurFile(data: any, id: String, roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/collaborateurs/uploadFile/' + id + '/', data, { observe: 'response' as 'body', responseType: 'text' });
  }


  uploadProspectFile(data: any, id: String, roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/clients/uploadFile/' + id + '/', data, { observe: 'response' as 'body', responseType: 'text' });
  }

  uploadProduitFile(data: any, id: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/produits/uploadFile/' + id, data, { observe: 'response' as 'body', responseType: 'text' });
  }

  getCollaborateurs(): Observable<Collaborateur[]> {
    return this.http.get<any>(this.baseUrl + '/query/collaborateurs/', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }
  getSuccursales(): Observable<Succursale[]> {
    return this.http.get<any>(this.baseUrl + '/query/succursales/', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }

  getProduitCategories(): Observable<ProduitCategorie[]> {
    return this.http.get<any>(this.baseUrl + '/query/categories/', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }

  deleteCategorie(id: any) {
    return this.http.delete(this.baseUrl + '/command/categories/' + id, { observe: 'response' as 'body', responseType: 'text' });
  }

  deleteProspect(id: any, idRoom: any) {
    return this.http.delete(this.baseUrl + '/command/clients/' + id + '/' + idRoom, { observe: 'response' as 'body', responseType: 'text' });
  }



  deleteSuccursale(id: any, roomId: any) {
    return this.http.delete(this.baseUrl + '/command/succursales/' + id + '/', { observe: 'response' as 'body', responseType: 'text' });
  }

  getEntreprises(): Observable<Entreprise[]> {
    return this.http.get<any>(this.baseUrl + '/query/entreprises/', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }

  getPostes(): Observable<Poste[]> {
    return this.http.get<any>(this.baseUrl + '/query/postes/', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }

  getVilles(): Observable<Ville[]> {
    return this.http.get<any>(this.baseUrl + '/query/villes/', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }
  getClients(type: any): Observable<Prospect[]> {
    return this.http.get<any>(this.baseUrl + '/query/clients/byEtat/' + type + '', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }
  getClientById(id: String): Observable<Prospect> {
    return this.http.get<any>(this.baseUrl + '/query/clients/' + id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }

  getSuccursaleById(id: String): Observable<Succursale> {
    return this.http.get<any>(this.baseUrl + '/query/succursales/' + id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }
  getProducts(): Observable<Produit[]> {
    return this.http.get<any>(this.baseUrl + '/query/produits/d', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }
  addSuccursale(data: any, roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/succursales/', data, { observe: 'response' as 'body', responseType: 'text' })
  }

  updateSuccursale(data: any, roomId: String): Observable<any> {
    return this.http.put(this.baseUrl + '/command/succursales/', data, { observe: 'response' as 'body', responseType: 'text' })
  }

  updateCollaborateur(data: any, roomId: String): Observable<any> {
    return this.http.put(this.baseUrl + '/command/collaborateurs/', data, { observe: 'response' as 'body', responseType: 'text' })
  }

  addPoste(data: any, roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/postes/', data, { observe: 'response' as 'body', responseType: 'text' })
  }

  addEntreprise(data: any): Observable<any> {
    return this.http.post(this.baseUrl + '/command/entreprises', data, { observe: 'response' as 'body', responseType: 'text' })
  }
  addProspect(data: any, roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/clients/', data, { observe: 'response' as 'body', responseType: 'text' })
  }

  updateProspect(data: any, roomId: String): Observable<any> {
    return this.http.put(this.baseUrl + '/command/clients/', data, { observe: 'response' as 'body', responseType: 'text' })
  }

  addProduit(data: any): Observable<any> {
    return this.http.post(this.baseUrl + '/command/produits', data, { observe: 'response' as 'body', responseType: 'text' })
  }

  addProduitCategorie(data: any): Observable<any> {
    return this.http.post(this.baseUrl + '/command/categories', data, { observe: 'response' as 'body', responseType: 'text' })
  }
  addProduitAndCategorie(data: any): Observable<any> {
    return this.http.post(this.baseUrl + '/command/produitCategories', data, { observe: 'response' as 'body', responseType: 'text' })
  }
  getEntityFileUrl(entity: String, fileName: String): string {
    //console.log(fileName)
    if (fileName == null || fileName == undefined || fileName == "" || fileName == "null" || fileName == "string" || fileName.indexOf("/data/user") >= 0) {
      if (entity != "reseaux" && entity != "equipes" && entity != "collaborateurs" && entity != "produits") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png";
      } else if (entity == "collaborateurs") {
        return "https://png.pngtree.com/png-vector/20210604/ourmid/pngtree-gray-avatar-placeholder-png-image_3416697.jpg";
      }
      else {
        return "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg";
      }
    }
    return this.baseUrl + '/command/' + entity + '/files/' + fileName + '/NSIACRM/1'
  }
}
import { RouteInfo } from './sidebar.metadata';

export const DASHBOARD_ROUTE: RouteInfo[] = [
 

  
];

export const ADMINISTRATION_ROUTE: RouteInfo[] = [
  {
    path: '/starter',
    title: 'Accueil',
    icon: 'ri-home-2-line',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: false,
    image: 'assets/images/svg/acceuil.svg',
    submenu: []
  },
  {
    path: '/prospect',
    title: 'Prospects',
    // icon: 'mdi mdi-chart-arc',
    icon: 'ri-group-line',
    class: '',
    label: '',
    labelClass: '',
    image: 'assets/images/svg/prospect.svg',
    extralink: false,
    submenu: []
  },
  // {
  //   path: '/contrat',
  //   title: 'Contrats',
  //   // icon: 'mdi mdi-chart-arc',
  //   icon: 'ri-group-line',
  //   class: '',
  //   label: '',
  //   labelClass: '',
  //   image: 'assets/images/svg/prospect.svg',
  //   extralink: false,
  //   submenu: []
  // },
  {
    path: '/client',
    title: 'Clients',
    // icon: 'mdi mdi-chart-arc',
    icon: 'ri-group-line',
    class: '',
    label: '',
    labelClass: '',
    image: 'assets/images/svg/prospect.svg',
    extralink: false,
    submenu: []
  },
  {
    path: '/sucursal',
    title: 'Succursales',
    // icon: 'mdi mdi-chart-arc',
    icon: 'mdi mdi-octagram',
    class: '',
    label: '',
    labelClass: '',
    image: 'assets/images/svg/succursale.svg',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Gestion de produits',
    icon: 'ri-box-3-line',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    image: 'assets/images/svg/produit.svg',
    extralink: false,
    submenu: [
      {
        path: 'produit/categorie',
        title: 'Catégories',
        icon: 'ri-list-unordered',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
       path: 'produit',
       title: 'Produits',
       icon: 'ri-box-3-line',
       class: '',
       label: '',
       labelClass: '',
       extralink: false,
       submenu: []
     },
    ]
  },
  {
    path: '/poste',
    title: 'Postes',
    // icon: 'mdi mdi-chart-arc',
    icon: 'ri-profile-line',
    class: '',
    label: '',
    labelClass: '',
    image: 'assets/images/svg/poste.svg',
    extralink: false,
    submenu: []
  },
 
  {
    path: '/reseau-list',
    title: 'Réseaux',
    icon: 'ri-flow-chart',
    class: '',
    label: '',
    labelClass: '',
    image: 'assets/images/svg/reseau.svg',
    extralink: false,
    submenu: []
  },
  {
    path: '/equipe',
    title: 'Equipes',
    icon: 'ri-team-line',
    class: '',
    label: '',
    labelClass: '',
    image: 'assets/images/svg/equipe.svg',
    extralink: false,
    submenu: []
  },
  {
    path: '/collaborateur',
    title: 'Collaborateurs',
    icon: 'ri-team-line',
    class: '',
    label: '',
    labelClass: '',
    image: 'assets/images/svg/collaborateur.svg',
    extralink: false,
    submenu: []
  },
 
  {
    path: '/user',
    title: 'Utilisateur',
    icon: 'ri-user-settings-line',
    class: '',
    label: '',
    labelClass: '',
    image: 'assets/images/svg/utilisateur.svg',
    extralink: false,
    submenu: []
  },
];

export const COMMERCIAL_ROUTE: RouteInfo[] = [
  {
    path: '/starter',
    title: 'Accueil',
    icon: 'ri-home-2-line',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: false,
    image: 'assets/images/svg/acceuil.svg',
    submenu: []
  },
  {
    path: '/prospect',
    title: 'Prospects',
    // icon: 'mdi mdi-chart-arc',
    icon: 'ri-group-line',
    class: '',
    label: '',
    labelClass: '',
    image: 'assets/images/svg/prospect.svg',
    extralink: false,
    submenu: []
  },
  {
    path: '/client',
    title: 'Clients',
    // icon: 'mdi mdi-chart-arc',
    icon: 'ri-group-line',
    class: '',
    label: '',
    labelClass: '',
    image: 'assets/images/svg/prospect.svg',
    extralink: false,
    submenu: []
  },
  
 
 
 
  
];

export const USER_ROUTE: RouteInfo[] = [
  {
    path: '',
    title: 'users',
    icon: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    submenu: []
  },
  {
    path: '',
    title: 'account-management',
    icon: 'mdi mdi-account-settings-variant',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
        path: 'client-provider/customer',
        title: 'customers',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: 'client-provider/provider',
        title: 'providers',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: 'client-provider/provider-kyc',
        title: 'kyc',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: 'client-provider/delete-account',
        title: 'delete-account',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      }
    ]
  },
];



export const SETTING_ROUTE: RouteInfo[] = [
  {
    path: '',
    title: 'settings',
    icon: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    submenu: []
  },
  {
    path: '#',
    title: 'country-town',
    icon: 'mdi mdi-flag',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
        path: 'country-setting/country',
        title: 'country',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: 'country-setting/town',
        title: 'town',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: '#',
    title: 'payment-method',
    icon: 'mdi mdi-treasure-chest',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
        path: 'payment-method/payment',
        title: 'aggregator',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: 'payment-method/payment-list',
        title: 'method',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },

    ]
  },
  {
    path: '#',
    title: 'diplome-menu',
    icon: 'mdi mdi-school',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
        path: 'diplome/diplome',
        title: 'diplome',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      }
    ]
  },
];

export const HELP_ROUTE: RouteInfo[] = [
 
];



import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from '../environments/environment';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Collaborateur, Entreprise, Equipe, Poste, ProductDynamicColumn, Produit, ProduitCategorie, Prospect, Reseau, Succursale, Ville } from "src/models/all-interfaces";

@Injectable()
export class ProduitService {
  private baseUrl: string = environment.productsApi;
 
  private userSource: BehaviorSubject<any>;
  constructor(private http: HttpClient, private translate: TranslateService, private router: Router) {

  }

  getCategorieById(id: String): Observable<ProduitCategorie> {
    return this.http.get<any>(this.baseUrl + '/query/categories/' + id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }

  getProduitById(id: String): Observable<Produit> {
    return this.http.get<any>(this.baseUrl + '/query/produits/withAllArgs/' + id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }
  listSuccursales(): Observable<Succursale[]> {
    return this.http.get<any>(this.baseUrl + '/query/succursales/', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }



  

  updateCategorie(data: any,roomId:String): Observable<any> {
    return this.http.put(this.baseUrl + '/command/categories/'+data.id, data, { observe: 'response' as 'body', responseType: 'text' })
  }

  updateProduit(data: any,roomId:String): Observable<any> {
    return this.http.put(this.baseUrl + '/command/produits/', data, { observe: 'response' as 'body', responseType: 'text' })
  }
  addCollaborateur(data: any): Observable<any> {
    return this.http.post(this.baseUrl + '/command/collaborateurs/', data, { observe: 'response' as 'body', responseType: 'text' })
  }
  addCollaborateurPoste(data: any): Observable<any> {
    return this.http.post(this.baseUrl + '/command/collaborateurPostes/', data, { observe: 'response' as 'body', responseType: 'text' });
  }

  addCollaborateurSuccursale(data: any): Observable<any> {
    return this.http.post(this.baseUrl + '/command/collaborateurSuccursales/', data, { observe: 'response' as 'body', responseType: 'text' });
  }
  uploadCollaborateurFile(data: any, id: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/collaborateurs/uploadFile/' + id, data, { observe: 'response' as 'body', responseType: 'text' });
  }

  uploadProspectFile(data: any, id: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/clients/uploadFile/' + id, data, { observe: 'response' as 'body', responseType: 'text' });
  }

  uploadProduitFile(data: any, id: String,roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/produits/uploadFile/' + id, data, { observe: 'response' as 'body', responseType: 'text' });
  }
  uploadFicheProduitFile(data: any, id: String,roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/produits/uploadFicheProduit/' + id+'/'+roomId, data, { observe: 'response' as 'body', responseType: 'text' });
  }

  uploadFicheQuestionnaireFile(data: any, id: String,roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/produits/uploadQuestionnaire/' + id+'/'+roomId, data, { observe: 'response' as 'body', responseType: 'text' });
  }


  getCollaborateurs(): Observable<Collaborateur[]> {
    return this.http.get<any>(this.baseUrl + '/query/collaborateurs/', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }
  getSuccursales(): Observable<Succursale[]> {
    return this.http.get<any>(this.baseUrl + '/query/succursales/', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }

  getProduitCategories(): Observable<ProduitCategorie[]> {
    return this.http.get<any>(this.baseUrl + '/query/categories/', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }

  getProductDynamicColumns(): Observable<ProductDynamicColumn[]> {
    return this.http.get<any>(this.baseUrl + '/query/dynamicColumns/', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }

  deleteCategorie(id:any,roomId:String){ 
    return this.http.delete(this.baseUrl + '/command/categories/' + id, { observe: 'response' as 'body', responseType: 'text' });
  }

  deleteProduit(id:any,roomId:String){ 
    return this.http.delete(this.baseUrl + '/command/produits/' + id, { observe: 'response' as 'body', responseType: 'text' });
  }

  deleteProspect(id:any){
    return this.http.delete(this.baseUrl + '/command/clients/' + id, { observe: 'response' as 'body', responseType: 'text' });
  }

  deleteSuccursale(id:any){
    return this.http.delete(this.baseUrl + '/command/succursales/' + id, { observe: 'response' as 'body', responseType: 'text' });
  }

  getEntreprises(): Observable<Entreprise[]> {
    return this.http.get<any>(this.baseUrl + '/query/entreprises/', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }



  getVilles(): Observable<Ville[]> {
    return this.http.get<any>(this.baseUrl + '/query/villes/', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }
  getClients(): Observable<Prospect[]> {
    return this.http.get<any>(this.baseUrl + '/query/clients/', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }
  getProducts(): Observable<Produit[]> {
    return this.http.get<any>(this.baseUrl + '/query/produits/withAllArgs/', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }

  getProductById(id: String): Observable<Produit> {
    return this.http.get<any>(this.baseUrl + '/query/produits/withAllArgs/'+id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }

  addSuccursale(data: any): Observable<any> {
    return this.http.post(this.baseUrl + '/command/succursales', data, { observe: 'response' as 'body', responseType: 'text' })
  }

 
  
  addEntreprise(data: any): Observable<any> {
    return this.http.post(this.baseUrl + '/command/entreprises', data, { observe: 'response' as 'body', responseType: 'text' })
  }
  addProspect(data: any): Observable<any> {
    return this.http.post(this.baseUrl + '/command/clients', data, { observe: 'response' as 'body', responseType: 'text' })
  }

  addProduit(data: any,roomId:String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/produits', data, { observe: 'response' as 'body', responseType: 'text' })
  }

  addProduitCategorie(data: any,roomId:String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/categories/', data, { observe: 'response' as 'body', responseType: 'text' })
  }
  addProduitAndCategorie(data: any,roomId:String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/produitCategories/', data, { observe: 'response' as 'body', responseType: 'text' })
  }

  updateProduitAndCategorie(data: any,roomId:String): Observable<any> {
    return this.http.put(this.baseUrl + '/command/produitCategories/', data, { observe: 'response' as 'body', responseType: 'text' })
  }
  addDynamicColumn(data: any,roomId:String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/dynamicColumns/', data, { observe: 'response' as 'body', responseType: 'text' })
  }
  addAttributProduit(data: any,roomId:String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/attributProduits/', data, { observe: 'response' as 'body', responseType: 'text' })
  }

  deleteAttributProduit(id: any,roomId:String) {
    return this.http.delete(this.baseUrl + '/command/attributProduits/'+id+'/', { observe: 'response' as 'body', responseType: 'text' })
  }

  updateAttributProduit(data: any,roomId:String): Observable<any> {
    return this.http.put(this.baseUrl + '/command/attributProduits/', data, { observe: 'response' as 'body', responseType: 'text' })
  }
  getEntityFileUrl(entity: String,fileName: String): string {
    console.log(fileName)
    if(fileName == null || fileName == undefined || fileName == "" || fileName == "null" || fileName == "string" || fileName.indexOf("/data/user") >= 0){
     if(entity != "reseaux" && entity != "equipes" && entity != "collaborateurs" && entity != "produits"){
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png";
    }else{
     return  "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg";
    }
  }
    return this.baseUrl + '/command/'+entity+'/files/'+fileName
  }
}
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from '../environments/environment';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Collaborateur, CollaborateurPoste, CollaborateurSuccursale, Contrat, Decharge, Entreprise, Equipe, Poste, Produit, ProduitCategorie, Prospect, Reseau, Succursale, Ville } from "src/models/all-interfaces";

@Injectable()
export class SouscriptionService {
  private baseUrl: string = environment.souscriptionApi;
  
  private userSource: BehaviorSubject<any>;
  constructor(private http: HttpClient, private translate: TranslateService, private router: Router) {

  }

  /*addSuccursale(): Observable<Succursale[]> {
      return this.http.get<any>(this.baseUrl + '/query/succursales', { observe: 'response' as 'body', responseType: 'json' }).pipe(
          catchError(error => {
              console.error(error);
              return of(null);
          }),
          map((response: any) => {
              return response.body;
          })
      )
  }*/
  listDechargesByProspect(id: String): Observable<Decharge[]> {
    return this.http.get<any>(this.baseUrl + '/query/decharges/byClientId/'+id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }
  listDechargesByCollaborateur(id: String): Observable<Decharge[]> {
    return this.http.get<any>(this.baseUrl + '/query/decharges/byCollaborateurId/'+id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }
  deleteDecharge(id:any,idRoom:any){
    return this.http.delete(this.baseUrl + '/command/decharges/' + id+'/', { observe: 'response' as 'body', responseType: 'text' });
  }

  uploadDechargeFile(data: any, id: String,roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/decharges/uploadFile/' + id+'/', data, { observe: 'response' as 'body', responseType: 'text' });
  }

  
  addDecharche(data: any,roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/decharges/', data, { observe: 'response' as 'body', responseType: 'text' })
  }

  updateDecharche(data: any,roomId: String): Observable<any> {
    return this.http.put(this.baseUrl + '/command/decharges/', data, { observe: 'response' as 'body', responseType: 'text' })
  }

  addContrat(data: any,roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/contrats/', data, { observe: 'response' as 'body', responseType: 'text' })
  }
  uploadContratFile(data: any, id: String,roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/contrats/uploadFile/' + id+'/', data, { observe: 'response' as 'body', responseType: 'text' });
  }
  listContrat(): Observable<Contrat[]> {
    return this.http.get<any>(this.baseUrl + '/query/contrats', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }

  listContratsByClientId(id: String): Observable<Contrat[]> {
    return this.http.get<any>(this.baseUrl + '/query/contrats/byClientId/'+id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }
  
  getEntityFileUrl(entity: String,fileName: String): string {
    console.log(fileName)
    if(fileName == null || fileName == undefined || fileName == "" || fileName == "null" || fileName == "string" || fileName.indexOf("/data/user") >= 0){
     if(entity != "reseaux" && entity != "equipes" && entity != "collaborateurs" && entity != "produits"){
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png";
    } else if(entity == "collaborateurs"){
      return  "https://png.pngtree.com/png-vector/20210604/ourmid/pngtree-gray-avatar-placeholder-png-image_3416697.jpg";
    }
    else{
     return  "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg";
    }
  }
    return this.baseUrl + '/command/'+entity+'/files/'+fileName
  }
}
import { RouteInfo } from './sidebar.metadata';
import {
  ADMINISTRATION_ROUTE,
  COMMERCIAL_ROUTE,
  DASHBOARD_ROUTE, HELP_ROUTE,
  
  SETTING_ROUTE,
  
  USER_ROUTE,
  
} from './menu-items-component';

export const ROLE_SUPER_ADMIN_MENU: RouteInfo[] = [
    ...DASHBOARD_ROUTE,
    ...ADMINISTRATION_ROUTE,
 
];
 
export const ROLE_COMMERCIAL: RouteInfo[] = [
  ...DASHBOARD_ROUTE,
  ...COMMERCIAL_ROUTE,

];

export const ROLE_ADVISOR_MENU: RouteInfo[] = [
  
  ...HELP_ROUTE
];

export const ROLE_MANAGER_MENU: RouteInfo[] = [
  ...USER_ROUTE,
  ...HELP_ROUTE
];

export const ROLE_MARKETING_MENU: RouteInfo[] = [
  ...USER_ROUTE,
  ...HELP_ROUTE
];

export const ROLE_SUPPORT_MENU: RouteInfo[] = [
  ...USER_ROUTE,
  ...HELP_ROUTE
];

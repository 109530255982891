
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  //private jsonUrl = '/assets/data/countries.data.json';
private mots : [] = [];
  constructor(private http: HttpClient) { }

  showAlert(error : any,method : string = "Une erreur est survenue"):void {
    console.log("erreur",error.status,method)
    if(error.status == 500 || error.status == 0){
      console.log("le status est 500")
      console.log("retour",this.parseMessage(error.message ,method))
      var retour = this.parseMessage(error.message ,method);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: retour,
        toast: true,
        //timer: 5000,
        position: 'top-end', // Position of the toast (top-end, top-start, bottom-end, bottom-start)
        showConfirmButton: true, // Hide the "OK" button
      });
    }
    
  }

  parseMessage(lmessage : string,method:string):string {
    var message = lmessage;
    switch (method) {
      case "POST":
        message = "Une erreur est survenue lors de l'ajout"
        break;
      case "PUT":
        message = "Une erreur est survenue lors de la mise à jour"
        break;
      case "DELETE":
        message = "Une erreur est survenue lors de la suppression"
        break;
      case "GET":
        message = "Une erreur est survenue lors de la récupération"
        break
      case "OPTIONS":
        message = "Une erreur est survenue lors de traitement"
        break;
      default:
        break;
    }
    console.log("type",message)
    if(lmessage.toUpperCase().includes("COLLABORATEUR")){
      message = message + " de collaborateur" ;
    }
    if(lmessage.toUpperCase().includes("CLIENT")){
      message = message + " de client" ;
    }
    if(lmessage.toUpperCase().includes("PRODUIT")){
      message = message + " de produit" ;
    }
    if(lmessage.toUpperCase().includes("CATEGORIE")){
      message = message + " de catégories de produit" ;
    }
    if(lmessage.toUpperCase().includes("SUCCURSALES")){
      message = message + " de succursale" ;
    }
    if(lmessage.toUpperCase().includes("EQUIPE")){
      message = message + " de équipe" ;
    }
    if(lmessage.toUpperCase().includes("RESEAUX")){
      message = message + " de réseaux" ;
    }
    if(lmessage.toUpperCase().includes("OBJECTIFS")){
      message = message + " de objectifs" ;
    }
    if(lmessage.toUpperCase().includes("DECHARGE")){
      message = message + " de décharges" ;
    }
    if(lmessage.toUpperCase().includes("CONTRAT")){
      message = message + " de contrats" ;
    }
    if(lmessage.toUpperCase().includes("DYNAMICCOLUMNS")){
      message = message + " de champs dynamics de produit" ;
    }
    if(lmessage.toUpperCase().includes("POSTE")){
      message = message + " de postes" ;
    }
    if(lmessage.toUpperCase().includes("USER")){
      message = message + " des utilisateurs" ;
    }
    console.log("message",message)
    return message;
  }
}
